import React, { useCallback, useState } from 'react';
import { deepmerge } from '@mui/utils';
import { Box, Container, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { BASENAME } from '../../utils/common.util';
import mainTheme from '../../utils/theme.util';
import { toast } from 'react-toastify';
import { ConsoleLogger } from '../../utils/logger.util';
import { SocialLoginProviders, linkToSocialProvider } from '../../utils/social-providers.util';
import { SocialLoginButtons } from '../../utils/components.util';
import './LinkSocialLogin.css';

const logger = new ConsoleLogger({ context: 'link-social-login' });

const theme = createTheme(deepmerge(mainTheme, {}));

const LinkSocialLoginPage = () => {
  const [disableAll, setDisableAll] = useState<boolean>(false);

  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const handleSocialLoginBtnClick = useCallback(async (provider: SocialLoginProviders) => {
    logger.debug(`clicked the social login button of the "${provider}" provider`);

    if (disableAll) {
      logger.warn(`do not proceed with social login of the ${provider} provider, beacuse it's all disabled`);

      return;
    }

    setDisableAll(true);

    try {
      await linkToSocialProvider(provider);
    } catch (err) {
      setDisableAll(false);

      toast.error(translate('pages.login.errors.not-logged-in'));

      toast.error(err.message || translate('errors.unknown'));
    }
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <a href={`${BASENAME}`}>
            <img src={`${BASENAME}/apifire-logo.png`} alt={translate('main.title')} className="logo" />
          </a>
          <Box component="form" onSubmit={() => {}} noValidate sx={{ mt: 1 }}>
            <SocialLoginButtons isUserLogged={true} handleProvider={handleSocialLoginBtnClick} />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default LinkSocialLoginPage;

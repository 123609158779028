import { Box, Button, Container, createTheme, CssBaseline, Divider, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import React, { FormEvent, MouseEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BASENAME, DISPATCH_URLS } from '../../utils/common.util';
import { ConsoleLogger } from '../../utils/logger.util';
import mainTheme from '../../utils/theme.util';
import { ApiRole, ApiUser, UserApis } from '../../utils/user/user.api';
import { SessionApis } from '../../utils/session/sessions.api';
import PROFILE_GROUPS from '../profile/profile-groups.json';
import './Dispatch.css';

interface RoleDispatchItem {
  roleRegex: string;
  dispatchKeys: Array<'admin' | 'producer' | 'cat'>;
  restrictions?: Array<string>;
}

const ROLE_NAVIGATIONS: Array<RoleDispatchItem> = require('./role-dispatch-list.json') as Array<RoleDispatchItem>;

const logger = new ConsoleLogger({ context: 'dispacth' });

const theme = createTheme(deepmerge(mainTheme, {}));

const DispatchPage = (props: { loggedUserId?: string }) => {
  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;
  const [dispatchKeys, setDispatchKeys] = useState<Array<string>>([]);

  const loggedUserId = props.loggedUserId;

  useEffect(() => {
    SessionApis.getSettings()
      .then(({ nodes }) => {
        const needToRedirect = PROFILE_GROUPS.policies.reduce((mandatoryPoliciesNotSelected, policy) => {
          const naAccepted = nodes.find(
            ({ attributes }) => attributes.name === `traits.${policy.code}.accepted`,
          )?.attributes;

          return (
            mandatoryPoliciesNotSelected ||
            (policy.mandatory && (!naAccepted || (naAccepted.value || false).toString().toLowerCase() !== 'true'))
          );
        }, false);

        if (needToRedirect) {
          logger.info('mandatory policies not accepted, redirecting to mandatory policies page');

          window.location.href = `${BASENAME}/mandatory-policies`;
        }
      })
      .catch(logger.error);

    UserApis.selfDetails(loggedUserId)
      .then((resData) => {
        const loggedUser = (resData || { roles: [] as Array<ApiRole> }) as ApiUser;

        logger.debug(`Logged user: ${JSON.stringify(loggedUser)}`);

        const dkList = ROLE_NAVIGATIONS.filter(
          (rnItem) =>
            (loggedUser.roles || []).map((r) => r.id).filter((role) => new RegExp(rnItem.roleRegex).test(role)).length >
              0 &&
            (!rnItem.restrictions ||
              rnItem.restrictions.reduce(
                (allowed, restriction) => allowed && !!(loggedUser.restrictions || {})[restriction],
                true,
              )),
        )
          .map((rnItem) => rnItem.dispatchKeys)
          .reduce((prev, curr) => prev.concat(curr.filter((c) => !prev.includes(c))), []) as Array<string>;

        logger.debug(`Dispatch keys: ${JSON.stringify(dkList)}`);

        setDispatchKeys(dkList);
      })
      .catch(logger.error);
  }, []);

  const dispacthTo = async (event: MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();

    const dispatchKey = (event.target as HTMLButtonElement).getAttribute('dispatch-key') || '';

    logger.info(`dispatch to ${dispatchKey}`);

    const dispatchUrl = DISPATCH_URLS.find((du) => du.key === dispatchKey)?.url;

    if (dispatchUrl) {
      window.location.href = dispatchUrl;
    } else {
      logger.error(`dispatch key "${dispatchKey}" not found in dispatch urls: ${JSON.stringify(DISPATCH_URLS)}`);

      toast.error(translate('pages.dispatch.errors.key-not-found'));
    }
  };

  const renderSpacingRow = () => {
    if (dispatchKeys && dispatchKeys.length > 0) {
      return <Divider variant="middle" sx={{ mt: 2, mb: 2 }} />;
    }

    return '';
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={`${BASENAME}/apifire-logo.png`} alt={translate('main.title')} className="logo" />
          <Box
            component="form"
            onSubmit={(event: FormEvent<HTMLFormElement>) => event.preventDefault()}
            noValidate
            sx={{ mt: 1 }}
          >
            {dispatchKeys.map((dispatchKey: string) => (
              <Button
                type="button"
                key={dispatchKey}
                onClick={dispacthTo}
                dispatch-key={dispatchKey}
                fullWidth
                variant="contained"
                sx={{ mt: 1, mb: 1, textTransform: 'none' }}
              >
                {translate(`pages.dispatch.${dispatchKey}`)}
              </Button>
            ))}
            {renderSpacingRow()}
            <Button
              type="button"
              onClick={dispacthTo}
              dispatch-key="user-profile"
              fullWidth
              variant="outlined"
              sx={{ mt: 1, mb: 1 }}
            >
              {translate('pages.dispatch.user-profile')}
            </Button>
            <Button
              type="button"
              onClick={dispacthTo}
              dispatch-key="change-password"
              fullWidth
              variant="outlined"
              sx={{ mt: 1, mb: 1 }}
            >
              {translate('pages.dispatch.change-password')}
            </Button>
            <Button
              type="button"
              onClick={dispacthTo}
              dispatch-key="link-social-login"
              fullWidth
              variant="outlined"
              sx={{ mt: 1, mb: 1 }}
            >
              {translate('pages.dispatch.link-social-login')}
            </Button>
            <Button
              type="button"
              onClick={dispacthTo}
              dispatch-key="delete-account"
              fullWidth
              variant="outlined"
              sx={{ mt: 1, mb: 1 }}
            >
              {translate('pages.dispatch.delete-account')}
            </Button>
            <Button
              type="button"
              onClick={dispacthTo}
              dispatch-key="logout"
              fullWidth
              variant="outlined"
              sx={{ mt: 1, mb: 1 }}
            >
              {translate('pages.dispatch.logout')}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default DispatchPage;

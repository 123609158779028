import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, UserState } from './user.types';
import { getUser, saveUser } from './user.client';

const initialState: UserState = {
  user: null,
  loading: 'idle',
  error: null,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload;

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUser.pending, (state) => {
      state.loading = 'pending';
      state.user = null;
      state.error = null;
    });
    builder.addCase(getUser.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.user = Object.assign({}, action.payload);
      state.error = null;
    });
    builder.addCase(getUser.rejected, (state, action) => {
      state.loading = 'failed';
      state.user = null;
      state.error = action.error.message || null;
    });
    builder.addCase(saveUser.pending, (state) => {
      state.loading = 'pending';
      state.user = null;
      state.error = null;
    });
    builder.addCase(saveUser.fulfilled, (state, action) => {
      state.loading = 'succeeded';
      state.user = Object.assign({}, action.payload);
      state.error = null;
    });
    builder.addCase(saveUser.rejected, (state, action) => {
      state.loading = 'failed';
      state.user = null;
      state.error = action.error.message || null;
    });
  },
});

export const { setUser } = userSlice.actions;
export default userSlice.reducer;

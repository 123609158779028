import React from 'react';
import { FadeLoader } from 'react-spinners';

const LoadingPage = () => (
  <div
    style={{
      width: '100%',
      height: '100vh',
      position: 'fixed',
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      zIndex: 1,
    }}
  >
    <FadeLoader
      cssOverride={{
        display: 'block',
        margin: '40vh auto',
      }}
    />
  </div>
);

export default LoadingPage;

import { Box, Container, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { ReactElement, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import { BASENAME } from '../../utils/common.util';
import { ConsoleLogger } from '../../utils/logger.util';
import { SessionApis } from '../../utils/session/sessions.api';
import mainTheme from '../../utils/theme.util';
import './Error.css';

const logger = new ConsoleLogger({ context: 'error-details' });

const theme = createTheme(deepmerge(mainTheme, {}));

function ErrorPage() {
  useQueryClient();

  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const queryParams = new URLSearchParams(window.location.search);
  const errorId = queryParams.get('id');

  logger.debug(`error id parameter: ${errorId}`);

  const [errorDetails, setErrorDetails] = useState<string | ReactElement>();

  const res = errorId
    ? useQuery<{ status: number; error?: any }>(['error-details'], () => SessionApis.getErrorDetails(errorId), {
      enabled: true,
    })
    : {
      isLoading: false,
      isSuccess: false,
      data: null,
      error: 'Unknown Error',
    };

  logger.debug(`Error details response: ${JSON.stringify(res)}`);

  if (res.isLoading) {
    return <LoadingPage />;
  }
  if (!errorDetails) {
    let details: string | ReactElement = '';

    if (res.isSuccess && res.data?.error) {
      const { id, code, reason, status, message } = res.data.error as {
        id: string;
        code: number;
        reason: string;
        status: string;
        message: string;
      };

      details = (
        <>
          Error: {code ? <>{code}</> : null}
          {status ? <> {status}</> : null}
          <br />
          {id ? (
            <>
              Id: {id}
              <br />
            </>
          ) : null}
          {message ? (
            <>
              Message: {message}
              <br />
            </>
          ) : null}
          {reason ? (
            <>
              Reason: {reason}
              <br />
            </>
          ) : null}
        </>
      );

      // If error is for expired session at logout, redirect to login
      if (
        id === 'session_inactive' &&
        !window.localStorage.getItem('session_json') &&
        !window.localStorage.getItem('logout_url')
      ) {
        window.location.href = './login';
      }
    } else if (res.error) {
      const error = res.error as { message: string };

      details = error?.message;
    }

    setErrorDetails(details || 'Unknown Error');
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <a href={`${BASENAME}`}>
            <img src={`${BASENAME}/apifire-logo.png`} alt={translate('main.title')} className="logo" />
          </a>
          <div className="error-details">{errorDetails}</div>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default ErrorPage;

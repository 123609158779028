import React, { useEffect, useState } from 'react';
import { SessionState } from '../../utils/session/session.types';
import { UserApis } from '../../utils/user/user.api';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import { Navigate } from 'react-router-dom';
import ErrorPage from '../error/Error';
import { SessionApis } from '../../utils/session/sessions.api';
import { ConsoleLogger } from '../../utils/logger.util';

const logger = new ConsoleLogger({ context: 'settings-dispatch' });

const SettingsDispatch = (props: { session: SessionState }) => {
  const { session } = props;

  const dt = localStorage.getItem('comes-from-link-social-provider');

  if (dt) {
    localStorage.removeItem('comes-from-link-social-provider');

    if (new Date().getTime() - new Date(dt).getTime() < 15 * 60 * 1000) {
      return <Navigate to="/" replace />;
    }
  }

  const [passwordLoading, setPasswordLoading] = useState<boolean>(true);
  const [sessionLoading, setSessionLoading] = useState<boolean>(true);

  const [hasPassword, setHasPassword] = useState<boolean>(false);
  const [sessionFetched, setSessionFetched] = useState<boolean>(false);

  useEffect(() => {
    UserApis.hasPassword(session?.session?.id || '')
      .then((res) => {
        logger.debug(`hasPassword: ${JSON.stringify(res)}`);

        setHasPassword(!!res);

        setPasswordLoading(false);
      })
      .catch((err) => {
        logger.error(`Error: ${err}`);
        setPasswordLoading(false);
      });

    SessionApis.fetchSession()
      .then((data) => {
        logger.debug(`fetch session success: ${JSON.stringify(data)}`);

        setSessionFetched(!!data.session);

        setSessionLoading(false);
      })
      .catch((err) => {
        logger.error(`Error: ${err}`);
        setSessionLoading(false);
      });
  }, []);

  if (passwordLoading || sessionLoading) {
    return <LoadingPage />;
  }

  if (hasPassword && sessionFetched) {
    logger.debug('redirecting to /change-password');

    return <Navigate to="/change-password" replace />;
  }

  if (!hasPassword && !sessionFetched) {
    logger.debug('redirecting to /first-settings');

    return <Navigate to="/first-settings" replace />;
  }

  return <ErrorPage />;
};

export default SettingsDispatch;

import {
  Box,
  Button,
  Container,
  createTheme,
  CssBaseline,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  ThemeProvider
} from '@mui/material';
import { deepmerge } from '@mui/utils';
import { useQueryClient } from '@tanstack/react-query';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BASENAME } from '../../utils/common.util';
import { ConsoleLogger } from '../../utils/logger.util';
import mainTheme from '../../utils/theme.util';

import { LoadingButton } from '@mui/lab';
import { Navigate, useNavigate } from 'react-router-dom';
import { SessionState } from '../../utils/session/session.types';
import { UserApis } from '../../utils/user/user.api';

const logger = new ConsoleLogger({ context: 'delete-account' });

const theme = createTheme(deepmerge(mainTheme, {}));

const DeleteAccountPage = (props: { stateSession: SessionState }) => {
  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const { stateSession } = props;

  if (!stateSession || !stateSession.session) {
    return <Navigate to="/login" replace />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <a href={`${BASENAME}`}>
            <img src={`${BASENAME}/apifire-logo.png`} alt={translate('main.title')} className="logo" />
          </a>
          <DeleteAccountComponent uuid={stateSession.session.identity.id} email={stateSession.session.identity.traits.email} />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

const DeleteAccountComponent = (props: { uuid: string; email: string }) => {
  useQueryClient();

  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const email = props.email?.trim().toLowerCase();
  const uuid = props.uuid;

  const navigate = useNavigate();
  const [canSubmit, setCanSubmit] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleSubmit = async (): Promise<void> => {
    logger.info('delete submit');

    setIsDeleting(true);
    setCanSubmit(false);

    UserApis.selfDelete(uuid).then(() => {
      setOpen(false);
      toast.success(translate('pages.delete-account.success'), {
        onClose: () => {
          navigate('/logout', { replace: true });
        }
      });
    }, (e) => {
      logger.error(e);
      toast.error(translate('errors.unknown'));
      setCanSubmit(true);
    }).finally(() => {
      setIsDeleting(false);
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box component="form" onSubmit={handleClickOpen}>
      <Box sx={{ width: '100%' }}>
        <p style={{ 'textAlign': 'center' }}>{translate('pages.delete-account.info')}</p>
        <TextField
          margin="normal"
          fullWidth
          name={'delete-account-email'}
          label={translate('pages.delete-account.email')}
          type='email'
          required={true}
          onInput={(event) => {
            const val = (event.target as HTMLInputElement).value?.trim().toLowerCase();

            setCanSubmit(!!(email && val === email));
          }}
        />
      </Box>
      <LoadingButton disabled={!canSubmit} fullWidth type="submit" loading={isDeleting} variant="contained" sx={{ mt: 2, mb: 2 }}>
        {translate('pages.delete-account.delete')}
      </LoadingButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {translate('pages.delete-account.confirm-title')}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {translate('pages.delete-account.confirm-desc-1')}
            <br/>
            {translate('pages.delete-account.confirm-desc-2')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>{translate('pages.delete-account.cancel')}</Button>
          <Button onClick={handleSubmit}>{translate('pages.delete-account.delete')}</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default DeleteAccountPage;

import React, { ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, FormGroup, FormControlLabel, Input } from '@mui/material';
import reactStringReplace from 'react-string-replace';
import { SocialLoginProviders, getSupportedSocialLoginProviders } from './social-providers.util';
import { AppleLoginButton, FacebookLoginButton, GoogleLoginButton } from 'react-social-login-buttons';
import { getTermsAndPrivacyPolicyUrl } from './common.util';
import { ConsoleLogger } from './logger.util';

const logger = new ConsoleLogger({ context: 'components-util' });

export const substituteTermsAndPrivacyPolicy = (
  policyCode: string,
  policyText: string,
  translate: (txt: string) => string,
): ReactNode => {
  if (policyCode === 'terms_policy' || policyCode === 'privacy_policy') {
    return reactStringReplace(
      policyText,
      `[[${policyCode}]]`,
      (): ReactNode => (
        <a href={getTermsAndPrivacyPolicyUrl(policyCode, undefined)} target="_blank" rel="noopener noreferrer">
          {translate(policyCode === 'terms_policy' ? 'commons.terms-plicy-label' : 'commons.privacy-policy-label')}
        </a>
      ),
    );
  }

  return <>{policyText}</>;
};

export const PolicyCheckField = (props: {
  title: string;
  name: string;
  value?: string | boolean;
  required: boolean;
  disabled: boolean;
  code: string;
  policyData?: {
    dateName: string;
    dateValue?: string;
    kindName: string;
    kindValue?: string;
  };
  disableAll: boolean;
}) => {
  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.policyData?.dateName) {
      props.policyData.dateValue = event.target.checked ? new Date().toISOString() : undefined;
    }
  };

  return (
    <>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              required={!!props.required}
              disabled={!!props.disabled}
              name={props.name}
              value={props.value as boolean}
              onChange={handleChange}
            />
          }
          label={substituteTermsAndPrivacyPolicy(
            props.code,
            translate(`pages.first-settings.fields.${props.title}`),
            translate,
          )}
          disabled={props.disableAll}
        />
      </FormGroup>
      {!!props.policyData?.dateName && (
        <Input type="hidden" name={props.policyData.dateName} value={props.policyData.dateValue} />
      )}
      {!!props.policyData?.kindName && (
        <Input type="hidden" name={props.policyData.kindName} value={props.policyData.kindValue} />
      )}
    </>
  );
};

export const SocialLoginButton = (props: {
  provider: SocialLoginProviders;
  handleProvider: (provider: SocialLoginProviders) => void;
}) => {
  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  if (props.provider === 'google') {
    return <GoogleLoginButton onClick={() => props.handleProvider(props.provider)} />;
  }

  if (props.provider === 'apple') {
    return <AppleLoginButton onClick={() => props.handleProvider(props.provider)} />;
  }

  if (props.provider === 'facebook') {
    return <FacebookLoginButton onClick={() => props.handleProvider(props.provider)} />;
  }

  return <>{translate('alerts.provider-not-supported')}</>;
};

export const SocialLoginButtons = (props: {
  isUserLogged: boolean;
  handleProvider: (provider: SocialLoginProviders) => void;
  before?: ReactNode;
  after?: ReactNode;
}) => {
  const [providers, setProviders] = useState([] as Array<SocialLoginProviders>);

  useEffect(() => {
    getSupportedSocialLoginProviders(props.isUserLogged).then(setProviders).catch(console.error);
  }, []);

  logger.debug(` +++ providers: ${JSON.stringify(providers)}`);

  return (
    <>
      {providers.length > 0 && (
        <>
          {props.before}
          {providers.map((provider) => (
            <SocialLoginButton key={provider} provider={provider} handleProvider={props.handleProvider} />
          ))}
          {props.after}
        </>
      )}
    </>
  );
};

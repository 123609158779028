import { Box, Container, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import { BASENAME, KRATOS_API } from '../../utils/common.util';
import { ConsoleLogger } from '../../utils/logger.util';
import { SessionApis } from '../../utils/session/sessions.api';
import mainTheme from '../../utils/theme.util';
import './Recovery.css';

const logger = new ConsoleLogger({ context: 'Recovery' });

const theme = createTheme(deepmerge(mainTheme, {}));

const resendRecoveryEmail = () => {
  window.location.href = `${KRATOS_API}/self-service/recovery/browser`;
};

function RecoveryPage() {
  useQueryClient();

  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const queryParams = new URLSearchParams(window.location.search);
  const flow = queryParams.get('flow');

  logger.debug(`recovery flow: ${flow}`);

  if (!flow) {
    resendRecoveryEmail();

    return <>{translate('pages.recovery.errors.flow-not-found')}</>;
  }

  const [recoveryResult, setRecoveryResult] = useState<{
    recovered: boolean;
    messages: Array<{ id: number; text: string; type: 'info' | 'error' | 'success'; reason?: string }>;
  }>();

  const res = useQuery<{
    status: number;
    passwordRecovered: boolean;
    messages: Array<{ id: number; text: string; type: 'info' | 'error' | 'success'; reason?: string }>;
  }>(['recovery-result'], () => SessionApis.getRecoveryResult(flow), {
    enabled: true,
  });

  logger.debug(`recovery result response: ${JSON.stringify(res)}`);

  if (res.isLoading) {
    return <LoadingPage />;
  }
  if (!recoveryResult) {
    setRecoveryResult({
      recovered: res.isSuccess && res.data?.status >= 200 && res.data?.status < 300 && res.data?.passwordRecovered,
      messages: res.data?.messages || [],
    });
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <a href={`${BASENAME}`}>
            <img src={`${BASENAME}/apifire-logo.png`} alt={translate('main.title')} className="logo" />
          </a>
          <div>
            {translate(
              recoveryResult?.recovered ? 'pages.recovery.email-recovery-ok' : 'pages.recovery.email-recovery-ko',
            )}
          </div>
          {(recoveryResult?.messages || []).length > 0 && (
            <ul className="message-list">
              {(recoveryResult?.messages || []).map((m) => {
                const trMsg = translate(`pages.recovery.errors.msg-${m.id}`);

                if (trMsg && trMsg !== `pages.recovery.errors.msg-${m.id}`) {
                  return (
                    <li key={m.id} className={`message-${m.type}`}>
                      {`${trMsg}${m.reason ? ' ' + m.reason : ''}`}
                    </li>
                  );
                }

                return (
                  <li key={m.id} className={`message-${m.type}`}>
                    <span>{m.id}</span>
                    {m.text}
                  </li>
                );
              })}
            </ul>
          )}
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default RecoveryPage;

import { Box, Button, Container, createTheme, CssBaseline, TextField, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { BASENAME, checkEmail } from '../../utils/common.util';
import { ConsoleLogger } from '../../utils/logger.util';
import mainTheme from '../../utils/theme.util';
import { SessionApis } from '../../utils/session/sessions.api';
import { toast } from 'react-toastify';
import './RecoverPassword.css';

import EmailIcon from '@mui/icons-material/Email';

const logger = new ConsoleLogger({ context: 'recover password' });

const theme = createTheme(deepmerge(mainTheme, {}));

function RecoverPasswordPage(props: { alreadyLogged?: boolean }) {
  const { t } = useTranslation();
  const [errors, setErrors] = useState<{ email?: string }>({});
  const translate = (text: string) => t(text) || text;

  if (props?.alreadyLogged) {
    logger.debug('user already logged');

    return <Navigate to="/" replace />;
  } else {
    logger.debug('user not logged yet');
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;

    const errors: { email?: string } = {};

    if (!checkEmail(email)) {
      errors.email = translate('pages.recover-password.errors.email');
    }

    if (errors.email) {
      setErrors(errors);

      return;
    }

    const recoverPasswordRet = await SessionApis.recoverPassword(email);

    if (recoverPasswordRet) {
      logger.info('Password recovery successful');

      toast.success(translate('pages.recover-password.success.recovered'));
    } else {
      logger.error('Password recovery failed');

      toast.error(translate('pages.recover-password.errors.not-recovered'));
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <a href={`${BASENAME}`}>
            <img src={`${BASENAME}/apifire-logo.png`} alt={translate('main.title')} className="logo" />
          </a>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label={translate('pages.recover-password.email-address')}
              type="email"
              name="email"
              autoComplete="email"
              autoFocus
              error={!!errors.email}
              helperText={errors.email}
              InputProps={{ startAdornment: <EmailIcon /> }}
            />

            <div className="login-text">{translate('pages.recover-password.instructions')}</div>

            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
              {translate('pages.recover-password.recover')}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default RecoverPasswordPage;

import {
  FrontendApi,
  Configuration,
  UiNodeInputAttributes,
  UiNode,
  UiNodeGroupEnum,
  UiContainer,
  UpdateLoginFlowBody,
  UpdateSettingsFlowBody,
} from '@ory/kratos-client';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { KRATOS_API } from '../common.util';
import { ConsoleLogger } from '../logger.util';

export interface SessionError {
  group?: UiNodeGroupEnum | 'undefined';
  field?: string;
  message: string;
  reason?: string;
}

const logger = new ConsoleLogger({ context: 'ory-client' });

const oryClient = new FrontendApi(
  new Configuration({
    basePath: KRATOS_API,
    baseOptions: {
      withCredentials: true,
    },
  }),
);

export const fetchSession = createAsyncThunk('session/get', async () => {
  const resp = await oryClient.toSession();

  return { session: resp.data, logoutUrl: await getLogoutUrl() };
});

export const login = createAsyncThunk('session/login', async (body: UpdateLoginFlowBody, thunkAPI) => {
  const resp = await oryClient.createBrowserLoginFlow();
  const flow = resp.data;

  body.method = 'password';
  const csfrNode = flow.ui.nodes.find((n) => (n.attributes as UiNodeInputAttributes).name === 'csrf_token') as UiNode;

  if (csfrNode) {
    body.csrf_token = (csfrNode.attributes as UiNodeInputAttributes).value;
  }
  const resp1 = await oryClient.updateLoginFlow({
    flow: flow.id,
    updateLoginFlowBody: body,
  });

  if (resp1.status !== 200) {
    thunkAPI.rejectWithValue(resp1.status);

    return { session: null, logoutUrl: '' };
  }

  return { session: resp1.data, logoutUrl: await getLogoutUrl() };
});

export const recoverPassword = createAsyncThunk('session/recover-password', async (email: string, thunkAPI) => {
  const resp = await oryClient.createBrowserRecoveryFlow();

  if (resp.status !== 200) {
    thunkAPI.rejectWithValue(resp.status);
  }
  const csfrToken = (
    resp.data.ui.nodes.find((n) => (n.attributes as UiNodeInputAttributes).name === 'csrf_token')
      ?.attributes as UiNodeInputAttributes
  )?.value;

  const resp1 = await oryClient.updateRecoveryFlow({
    flow: resp.data.id,
    updateRecoveryFlowBody: {
      email,
      method: 'link',
      csrf_token: csfrToken,
    },
  });

  if (resp1.status !== 200) {
    thunkAPI.rejectWithValue(resp1.status);

    return;
  }

  return { session: null, logoutUrl: null };
});

export const changePassword = createAsyncThunk('session/change-password', async (password: string, thunkAPI) => {
  const initSettingsFlowResponse = await oryClient.createBrowserSettingsFlow();

  if (initSettingsFlowResponse.status !== 200) {
    const errors = uiContainerToErrors(initSettingsFlowResponse.data?.ui);

    logger.error(
      `error on submit change password settings [${initSettingsFlowResponse.status}]: ${JSON.stringify({
        messages: initSettingsFlowResponse.data?.ui?.messages || [],
        errors,
      })}`,
    );

    thunkAPI.rejectWithValue({
      message: 'error on initialize self service settings flow',
      status: initSettingsFlowResponse.status,
      errors,
    });

    return;
  }

  const csfrValue = (
    (
      initSettingsFlowResponse.data.ui.nodes.find(
        (n) => (n.attributes as UiNodeInputAttributes).name === 'csrf_token',
      ) as UiNode
    ).attributes as UiNodeInputAttributes
  ).value;

  if (!csfrValue) {
    logger.warn('csfr value not found');

    thunkAPI.rejectWithValue({ message: 'csfr value not found' });

    return;
  }

  try {
    const submitSettingsFlowResponse = await oryClient.updateSettingsFlow({
      flow: initSettingsFlowResponse.data.id,
      updateSettingsFlowBody: {
        password,
        method: 'password',
        csrf_token: csfrValue,
      },
    });

    if (submitSettingsFlowResponse.status !== 200) {
      const errors = uiContainerToErrors(submitSettingsFlowResponse.data?.ui);

      logger.error(
        `error on submit change password settings [${submitSettingsFlowResponse.status}]: ${JSON.stringify({
          messages: submitSettingsFlowResponse.data?.ui?.messages || [],
          errors: errors.length > 0 ? errors : ['errors.unknown'],
        })}`,
      );

      return thunkAPI.rejectWithValue({
        message: 'error on submit self service settings flow',
        status: submitSettingsFlowResponse.status,
        errors: errors.length > 0 ? errors : ['errors.unknown'],
      });
    }

    return thunkAPI.fulfillWithValue(submitSettingsFlowResponse.data);
  } catch (err) {
    logger.error('error on call update settings flow:', err);

    if (err && err.message && err.message === 'Request failed with status code 400') {
      return thunkAPI.rejectWithValue({
        message: 'error on submit self service settings flow',
        status: 400,
        errors: ['pages.change-password.errors.password-not-valid'],
      });
    }

    return thunkAPI.rejectWithValue({
      message: 'error on submit self service settings flow',
      status: 500,
      errors: [err && err.message ? err.message : 'errors.unknown'],
    });
  }
});

export const updateProfile = createAsyncThunk(
  'session/update-profile',
  async (fieldMap: { [name: string]: string | boolean | number }, thunkAPI) => {
    const initSettingsFlowResponse = await oryClient.createBrowserSettingsFlow();

    if (initSettingsFlowResponse.status !== 200) {
      const errors = uiContainerToErrors(initSettingsFlowResponse.data?.ui);

      logger.error(
        `error on submit change password settings [${initSettingsFlowResponse.status}]: ${JSON.stringify({
          messages: initSettingsFlowResponse.data?.ui?.messages || [],
          errors,
        })}`,
      );

      thunkAPI.rejectWithValue({
        message: 'error on initialize self service settings flow',
        status: initSettingsFlowResponse.status,
        errors,
      });

      return;
    }

    const csfrValue = (
      (
        initSettingsFlowResponse.data.ui.nodes.find(
          (n) => (n.attributes as UiNodeInputAttributes).name === 'csrf_token',
        ) as UiNode
      ).attributes as UiNodeInputAttributes
    ).value;

    if (!csfrValue) {
      logger.error('csfr value not found');

      thunkAPI.rejectWithValue({ message: 'csfr value not found' });

      return;
    }

    const submitSettingsFlowResponse = await oryClient.updateSettingsFlow({
      flow: initSettingsFlowResponse.data.id,
      updateSettingsFlowBody: {
        ...fieldMap,
        method: 'profile',
        csrf_token: csfrValue,
      } as UpdateSettingsFlowBody,
    });

    if (submitSettingsFlowResponse.status !== 200) {
      const errors = uiContainerToErrors(submitSettingsFlowResponse.data?.ui);

      logger.error(
        `error on submit change password settings [${submitSettingsFlowResponse.status}]: ${JSON.stringify({
          messages: submitSettingsFlowResponse.data?.ui?.messages || [],
          errors,
        })}`,
      );

      thunkAPI.rejectWithValue({
        message: 'error on submit self service settings flow',
        status: submitSettingsFlowResponse.status,
        errors,
      });

      return;
    }

    return submitSettingsFlowResponse.data;
  },
);

const getLogoutUrl = async () => {
  const resp = await oryClient.createBrowserLogoutFlow();

  return resp.data.logout_url;
};

export const uiContainerToErrors = (ui: UiContainer): Array<SessionError> => [
  ...(ui?.messages || []).map(
    (m) =>
      ({
        message: m.text,
      } as SessionError),
  ),
  ...(ui?.nodes || [])
    .filter((n) => (n.messages || []).filter((m) => m.type === 'error').length > 0)
    .map((n) => ({
      group: n.group,
      field: (n.attributes as UiNodeInputAttributes).name,
      messages: n.messages.map((m) => ({ text: m.text, reason: (m.context as { reason?: string })?.reason })),
    }))
    .reduce((errors, curr) => {
      return [
        ...errors,
        ...curr.messages.map((m) => ({
          field: curr.field,
          group: curr.group,
          message: m.text,
          reason: m.reason,
        })),
      ];
    }, [] as Array<SessionError>),
];

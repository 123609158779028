import { createAsyncThunk } from '@reduxjs/toolkit';
import { UserApis } from './user.api';
import { User } from './user.types';

export const getUser = createAsyncThunk('user/get', async (uuid: string) => {
  const user = await UserApis.fetchUser(uuid);

  return user;
});

export const saveUser = createAsyncThunk('user/save', async (user: User) => {
  const res = await UserApis.saveUser(user);

  return res;
});

import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  IconButton,
  InputAdornment,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { deepmerge } from '@mui/utils';
import { useQueryClient } from '@tanstack/react-query';
import React, { FormEvent, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { BASENAME, checkPassword } from '../../utils/common.util';
import { useAppDispatch } from '../../utils/hooks.util';
import { ConsoleLogger } from '../../utils/logger.util';
import { changePassword } from '../../utils/session/ory-client';
import mainTheme from '../../utils/theme.util';
import './ChangePassword.css';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { LoadingButton } from '@mui/lab';
import { Navigate, useNavigate } from 'react-router-dom';
import { SessionState } from '../../utils/session/session.types';

const logger = new ConsoleLogger({ context: 'change-password' });

const theme = createTheme(deepmerge(mainTheme, {}));

const ChangePasswordPage = (props: { stateSession: SessionState }) => {
  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const { stateSession } = props;

  if (!stateSession || !stateSession.session) {
    return <Navigate to="/login" replace />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <a href={`${BASENAME}`}>
            <img src={`${BASENAME}/apifire-logo.png`} alt={translate('main.title')} className="logo" />
          </a>
          <ChangePasswordComponent />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

const ChangePasswordComponent = () => {
  useQueryClient();

  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const [errors, setErrors] = useState<{ new?: string; repeat?: string }>({});
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [disableAll, setDisableAll] = useState<boolean>(false);
  const [isPasswordChanging, setIsPasswordChanging] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const dispatch = useAppDispatch();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setIsPasswordChanging(true);
    setDisableAll(true);

    const data = new FormData(event.currentTarget);
    const newPassword = data.get('field-new-password') as string;
    const repeatPassword = data.get('field-repeat-password') as string;

    const errors: { new?: string; repeat?: string } = {};

    if (!checkPassword(newPassword, logger)) {
      errors.new = translate('pages.change-password.errors.password');
    }

    if (!showPassword && repeatPassword !== newPassword) {
      errors.repeat = translate('pages.change-password.errors.repeat');
    }

    setErrors(errors);

    if (!!errors.new || !!errors.repeat) {
      setDisableAll(false);
      setIsPasswordChanging(false);

      return;
    } else {
      dispatch(changePassword(newPassword)).then((response: any) => {
        if (response.error) {
          setDisableAll(false);
          setIsPasswordChanging(false);

          const msgErrors = response.payload?.errors || [];

          if (msgErrors.length === 0) {
            msgErrors.push('errors.unknown');
          }

          for (const msgError of msgErrors) {
            toast.error(translate(msgError));
          }
        } else {
          navigate('/', { replace: true });
        }
      });
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <Box sx={{ width: '100%' }}>
        <TextField
          margin="normal"
          fullWidth
          name={'field-new-password'}
          label={translate('pages.change-password.password')}
          type={showPassword ? 'text' : 'password'}
          required={true}
          disabled={disableAll}
          error={!!errors.new}
          helperText={errors.new}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label={translate('pages.login.toggle-password-visibility')}
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          margin="normal"
          fullWidth
          name={'field-repeat-password'}
          label={translate('pages.change-password.check-password')}
          type="password"
          required={!showPassword}
          disabled={disableAll || showPassword}
          error={!!errors.repeat}
          helperText={errors.repeat}
        />
      </Box>
      <LoadingButton fullWidth type="submit" loading={isPasswordChanging} variant="contained" sx={{ mt: 2, mb: 2 }}>
        {translate('pages.change-password.save')}
      </LoadingButton>
    </Box>
  );
};

export default ChangePasswordPage;

import { Configuration, FrontendApi, UiNodeInputAttributes } from '@ory/kratos-client';
import { KRATOS_API, SOCIAL_LOGIN_PROVIDERS } from './common.util';
import { ConsoleLogger } from './logger.util';

const logger = new ConsoleLogger({ context: 'social-providers-util' });

export type SocialLoginProviders = 'google' | 'apple' | 'facebook';

const oryClient = new FrontendApi(
  new Configuration({
    basePath: KRATOS_API,
    baseOptions: {
      withCredentials: true,
    },
  }),
);

export const getSupportedSocialLoginProviders = async (
  userLogged: boolean = false,
): Promise<Array<SocialLoginProviders>> => {
  const resp = userLogged ? await oryClient.createBrowserSettingsFlow() : await oryClient.createBrowserLoginFlow();
  const nodeAttrName = userLogged ? 'link' : 'provider';

  const providers: Array<SocialLoginProviders> = (resp.data?.ui?.nodes || [])
    .filter(
      (n) =>
        n.group === 'oidc' &&
        (n.attributes as UiNodeInputAttributes).name === nodeAttrName &&
        !(n.attributes as UiNodeInputAttributes).disabled &&
        !!SOCIAL_LOGIN_PROVIDERS.includes((n.attributes as UiNodeInputAttributes).value),
    )
    .map((n) => (n.attributes as UiNodeInputAttributes).value)
    .sort();

  logger.debug(`providers: ${JSON.stringify(providers)}`);

  return providers;
};

export const doSocialProviderLogin = async (provider: SocialLoginProviders): Promise<void> => {
  const { data: bLoginFlowResp } = await oryClient.createBrowserLoginFlow();

  const flow = bLoginFlowResp.id;

  if (
    !(bLoginFlowResp.ui?.nodes || []).find(
      (n) =>
        n.group === 'oidc' &&
        n.attributes.node_type === 'input' &&
        (n.attributes as UiNodeInputAttributes).name === 'provider' &&
        (n.attributes as UiNodeInputAttributes).value === provider,
    )
  ) {
    logger.error(`provider ${provider} is not supported`);

    throw new Error(`provider ${provider} is not supported`);
  }

  const csrfToken = (
    (bLoginFlowResp.ui?.nodes || []).find(
      (n) =>
        n.group === 'default' &&
        n.attributes.node_type === 'input' &&
        (n.attributes as UiNodeInputAttributes).name === 'csrf_token',
    )?.attributes as UiNodeInputAttributes
  ).value;

  let redirectTo = undefined;

  try {
    const loginFlowResp = await oryClient.updateLoginFlow({
      flow,
      updateLoginFlowBody: {
        method: 'oidc',
        provider,
        csrf_token: csrfToken,
      },
    });

    if (loginFlowResp.status === 422 && loginFlowResp.data['error']['id'] === 'browser_location_change_required') {
      redirectTo = loginFlowResp.data['redirect_browser_to'];
    }
  } catch (err) {
    logger.warn('error on call ory update login flow', err);

    if (err.response?.data.error?.id === 'browser_location_change_required') {
      redirectTo = err.response.data.redirect_browser_to;
    }
  }

  if (redirectTo) {
    logger.info(`redirecting to ${redirectTo}`);

    window.location.href = redirectTo;
  } else {
    throw new Error('no redirect url');
  }
};

export const linkToSocialProvider = async (provider: SocialLoginProviders): Promise<void> => {
  const { data: bSettingsFlowResp } = await oryClient.createBrowserSettingsFlow();

  const flow = bSettingsFlowResp.id;

  if (
    !(bSettingsFlowResp.ui?.nodes || []).find(
      (n) =>
        n.group === 'oidc' &&
        n.attributes.node_type === 'input' &&
        (n.attributes as UiNodeInputAttributes).name === 'link' &&
        (n.attributes as UiNodeInputAttributes).value === provider,
    )
  ) {
    logger.error(`provider ${provider} is not supported`);

    throw new Error(`provider ${provider} is not supported`);
  }

  let redirectTo = undefined;

  try {
    const settingsFlowResp = await oryClient.updateSettingsFlow({
      flow,
      updateSettingsFlowBody: {
        method: 'oidc',
        link: provider,
      },
    });

    logger.debug(` +++ settingsFlowResp: ${JSON.stringify(settingsFlowResp)}`);

    if (
      settingsFlowResp.status === 422 &&
      settingsFlowResp.data['error']['id'] === 'browser_location_change_required'
    ) {
      redirectTo = settingsFlowResp.data['redirect_browser_to'];
    }
  } catch (err) {
    logger.warn('error on call ory update login flow', err);

    if (err.response?.data.error?.id === 'browser_location_change_required') {
      redirectTo = err.response.data.redirect_browser_to;
    }
  }

  if (redirectTo) {
    logger.info(`redirecting to ${redirectTo}`);

    localStorage.setItem('comes-from-link-social-provider', new Date().toISOString());

    window.location.href = redirectTo;
  } else {
    throw new Error('no redirect url');
  }
};

import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { isProduction } from './common.util';

import sessionReducers from './session/session.slice';
import userReducers from './user/user.slice';

export const store = configureStore({
  reducer: {
    session: sessionReducers,
    user: userReducers,
  },
  devTools: !isProduction,
});

store.subscribe(() => {
  const s = store.getState().session.session;

  if (s) {
    window.localStorage.setItem('session_json', JSON.stringify(s));
  } else {
    window.localStorage.removeItem('session_json');
  }

  const lu = store.getState().session.logoutURL;

  if (lu) {
    window.localStorage.setItem('logout_url', lu);
  } else {
    window.localStorage.removeItem('logout_url');
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;

import {
  FrontendApi,
  Configuration,
  Session,
  UiNodeInputAttributes,
  UpdateVerificationFlowBody,
  UpdateRecoveryFlowWithLinkMethod,
  UpdateLoginFlowBody,
} from '@ory/kratos-client';
import { KRATOS_API, ResponseMessage } from '../common.util';
import { ConsoleLogger } from '../logger.util';
import { SessionError, uiContainerToErrors } from './ory-client';

const logger = new ConsoleLogger({ context: 'sessions api' });

const oryClient = new FrontendApi(
  new Configuration({
    basePath: KRATOS_API,
    baseOptions: {
      withCredentials: true,
    },
  }),
);

export interface LoginResponse {
  session?: any;
  logoutUrl?: string;
  hasErrors?: boolean;
  errors?: SessionError[];
}

export class SessionApis {
  static async fetchSession(): Promise<{ session: Session | null; logoutUrl: string }> {
    logger.info('called fetch session method');

    const resp = await oryClient.toSession();

    if (resp.status === 200) {
      return { session: resp.data, logoutUrl: await SessionApis.getLogoutUrl() };
    }

    return { session: null, logoutUrl: '' };
  }

  static async login(
    identifier: string,
    password: string,
  ): Promise<{ hasErrors: false; session: any; logoutUrl: string } | { hasErrors: true; errors: Array<SessionError> }> {
    logger.info('called login method');
    const flowResp = await oryClient.createBrowserLoginFlow();

    const flowId = flowResp.data?.id;
    const flowUiNodes = flowResp.data?.ui?.nodes || [];
    const flowUi = flowResp.data?.ui;

    const csrfToken = (
      flowUiNodes.find((n) => (n.attributes as UiNodeInputAttributes).name === 'csrf_token')
        ?.attributes as UiNodeInputAttributes
    )?.value;

    if (flowResp.status !== 200 || !csrfToken) {
      const errors = uiContainerToErrors(flowUi);

      logger.error(
        `error on get login flow: ${JSON.stringify({
          responseStatus: flowResp.status,
          flowId,
          csrfToken,
          errors,
        })}`,
      );

      if (errors.length === 0) {
        errors.push({
          group: 'undefined',
          field: 'undefined',
          message: 'error on get login flow',
          reason: `response status: ${flowResp.status}`,
        });
      }

      return { hasErrors: true, errors };
    }

    const flow = flowResp.data;
    const body: UpdateLoginFlowBody = {
      method: 'password',
      identifier,
      password,
      csrf_token: '',
    };
    const csfrNode = flow.ui.nodes.find((n) => (n.attributes as UiNodeInputAttributes).name === 'csrf_token');

    if (csfrNode) {
      body.csrf_token = (csfrNode.attributes as UiNodeInputAttributes).value;
    }
    try {
      const loginResp = await oryClient.updateLoginFlow({
        flow: flow.id,
        updateLoginFlowBody: body,
      });

      return { hasErrors: false, session: loginResp.data, logoutUrl: await SessionApis.getLogoutUrl() };
    } catch (err) {
      const errors = uiContainerToErrors(err.response.data?.ui);

      logger.error(
        `error on do login: ${JSON.stringify({
          responseStatus: err.response.status,
          errors,
        })}`,
      );

      if (errors.length === 0) {
        errors.push({
          group: 'undefined',
          field: 'undefined',
          message: 'error on do login',
          reason: `response status: ${err.response.status}`,
        });
      }

      return { hasErrors: true, errors };
    }
  }

  static async recoverPassword(email: string): Promise<boolean | { hasErrors: true; errors: Array<SessionError> }> {
    logger.info('called recover password method');

    const flowResp = await oryClient.createBrowserRecoveryFlow();
    const flowUi = flowResp.data?.ui;
    const flowId = flowResp.data?.id;

    if (flowResp.status !== 200) {
      return false;
    }
    const flowNodes = flowResp.data?.ui?.nodes || [];
    const csfrValue = (
      flowNodes.find((n) => (n.attributes as UiNodeInputAttributes).name === 'csrf_token')
        ?.attributes as UiNodeInputAttributes
    )?.value;

    if (!csfrValue) {
      return false;
    }

    const recoveryBody: UpdateRecoveryFlowWithLinkMethod = {
      email,
      method: 'link',
      csrf_token: csfrValue,
    };

    const recoveryResp = await oryClient.updateRecoveryFlow({
      flow: flowResp.data.id,
      updateRecoveryFlowBody: recoveryBody,
    });

    if (recoveryResp.status !== 200) {
      const errors = uiContainerToErrors(flowUi);

      logger.error(
        `error on recover password flow: ${JSON.stringify({
          responseStatus: flowResp.status,
          flowId,
          errors,
        })}`,
      );

      if (errors.length === 0) {
        errors.push({
          group: 'undefined',
          field: 'undefined',
          message: 'error on get recover password flow',
          reason: `response status: ${flowResp.status}`,
        });
      }

      return { hasErrors: true, errors };
    }

    return true;
  }

  static async changePassword(password: string): Promise<{ session: any; logoutUrl: string } | null> {
    logger.info('called change password method');

    const resp = await oryClient.createBrowserSettingsFlow();

    if (resp.status !== 200) {
      return null;
    }
    const csfrNode = resp.data.ui.nodes.find((n) => (n.attributes as UiNodeInputAttributes).name === 'csrf_token');

    if (!csfrNode) {
      return null;
    }
    const resp1 = await oryClient.updateSettingsFlow({
      flow: resp.data.id,
      updateSettingsFlowBody: {
        password,
        method: 'password',
        csrf_token: (csfrNode.attributes as UiNodeInputAttributes).value,
      },
    });

    if (resp1.status !== 200) {
      return null;
    }

    return {
      session: resp1.data as Session,
      logoutUrl: await SessionApis.getLogoutUrl(),
    };
  }

  static async getLogoutUrl(): Promise<string> {
    logger.info('called get logout url method');

    const resp = await oryClient.createBrowserLogoutFlow();

    return resp.data.logout_url;
  }

  static async logout(): Promise<any> {
    logger.info('called logout method');

    const logoutToken = await this.getLogoutToken();

    const resp = oryClient.updateLogoutFlow({
      token: logoutToken,
    });

    return resp;
  }

  static async getLogoutToken(): Promise<string> {
    logger.info('called get logout token method');

    const resp = await oryClient.createBrowserLogoutFlow();

    return resp.data.logout_token;
  }

  static async getSettings(): Promise<{ action: string; nodes: Array<any> }> {
    logger.info('called get settings method');

    const resp = await oryClient.createBrowserSettingsFlow();

    return {
      action: resp.data.ui.action,
      nodes: resp.data.ui.nodes,
    };
  }

  static async getErrorDetails(errorId: string): Promise<{ status: number; error?: any }> {
    const resp = await oryClient.getFlowError({ id: errorId });

    return { status: resp.status, error: resp.data?.error };
  }

  static async resendVerificationLink(
    email: string,
  ): Promise<{ ok: false; error: string; reason: string } | { ok: true }> {
    try {
      const resp = await oryClient.createBrowserVerificationFlow();

      const flowId = resp.data.id;

      const csrfToken = (
        resp.data.ui.nodes.find(
          (n) => n.attributes.node_type === 'input' && (n.attributes as UiNodeInputAttributes).name === 'csrf_token',
        )?.attributes as UiNodeInputAttributes
      )?.value;

      await oryClient.updateVerificationFlow({
        flow: flowId,
        updateVerificationFlowBody: { email, method: 'code', csrf_token: csrfToken },
      });

      return { ok: true };
    } catch (err) {
      logger.error('error on resend verification link:', err);

      return { ok: false, error: err.message, reason: err.reason };
    }
  }

  static async verifyEmail(
    flowId: string,
    code: string,
    email: string,
  ): Promise<{ ok: false; error: string; reason: string } | { ok: true }> {
    try {
      const body: UpdateVerificationFlowBody = {
        code,
        email,
        method: 'code',
      };

      await oryClient.updateVerificationFlow({
        flow: flowId,
        updateVerificationFlowBody: body as UpdateVerificationFlowBody,
      });

      return { ok: true };
    } catch (err) {
      logger.error('error on resend verification link:', err);

      return { ok: false, error: err.message, reason: err.reason };
    }
  }

  static async getRecoveryResult(flowId: string): Promise<{
    status: number;
    passwordRecovered: boolean;
    messages: Array<ResponseMessage>;
  }> {
    const resp = await oryClient.getRecoveryFlow({ id: flowId });

    return {
      status: resp.status,
      passwordRecovered: resp.data?.state === 'passed_challenge',
      messages: (resp.data?.ui?.messages || []).map((m) => ({
        id: m.id,
        text: m.text,
        type: m.type,
        reason: m.context?.['reason'],
      })),
    };
  }

  static async getVerificationFlow() {
    const resp = await oryClient.createBrowserVerificationFlow();

    return resp.data.id;
  }
}

import axios from 'axios';
import { USERS_API } from '../common.util';
import { ConsoleLogger } from '../logger.util';
import { Role, User } from './user.types';

const logger = new ConsoleLogger({ context: 'users api' });

export interface ApiRole {
  id: string;
  description: string;
}

export interface ApiUser {
  id: string;
  traits: any;
  roles: Array<ApiRole>;
  restrictions?: Record<string, boolean>;
}

export class UserApis {
  static readonly api = USERS_API;

  static async hasPassword(userId: string): Promise<boolean | null> {
    const ret = await axios.get<{ data: boolean }>(`${UserApis.api}/self/has_password`, {
      withCredentials: false,
      headers: {
        'x-user': userId,
      },
    });

    if (ret.status !== 200) {
      return null;
    }

    return ret.data.data;
  }

  static async selfDetails(userId?: string): Promise<User | null> {
    logger.debug('called self detais method');

    if (!userId) {
      return null;
    }

    const ret = await axios.get<{ data: ApiUser }>(`${UserApis.api}/self`, {
      withCredentials: true,
      headers: {
        'x-user': userId,
      },
    });

    if (ret.status !== 200) {
      return null;
    }

    return UserApis.apiUserToUser(ret.data.data);
  }

  static async selfDelete(userId?: string): Promise<User | null> {
    logger.debug('called self delete method');

    const ret = await axios.delete<{ data: ApiUser }>(`${UserApis.api}/self`, {
      withCredentials: true,
      headers: {
        'x-user': userId,
      },
    });

    if (ret.status !== 200) {
      return null;
    }

    return UserApis.apiUserToUser(ret.data.data);
  }

  private static apiRoleToRole(apiRole: ApiRole): Role {
    return {
      id: apiRole.id,
      description: apiRole.description,
    } as Role;
  }

  private static apiUserToUser(apiUser: ApiUser): User {
    return {
      id: apiUser.id,
      email: apiUser.traits.email,
      name: apiUser.traits.name?.first,
      surname: apiUser.traits.name?.last,
      restrictions: apiUser.restrictions,
      roles: (apiUser.roles || []).map(UserApis.apiRoleToRole),
    } as User;
  }
}

import { UiContainer, UiText } from '@ory/kratos-client';
import { ConsoleLogger } from './logger.util';
import { toast } from 'react-toastify';
import { SocialLoginProviders } from './social-providers.util';

export const BASENAME = process.env.REACT_APP_BASENAME || '/';
export const PUBLIC_URL = process.env.PUBLIC_URL || BASENAME;

export const DISPATCH_URLS = [
  { key: 'user-profile', url: `${BASENAME}/user-profile` },
  { key: 'change-password', url: `${BASENAME}/change-password` },
  { key: 'delete-account', url: `${BASENAME}/delete-account` },
  { key: 'link-social-login', url: `${BASENAME}/link-social-login` },
  { key: 'logout', url: `${BASENAME}/logout` },
  ...(JSON.parse(process.env.REACT_APP_DISPATCH_URLS || '[]') as Array<{
    key: string;
    url: string;
  }>),
];

export const isLocalhost = window.location.hostname === 'localhost';

export const isProduction = !isLocalhost && process.env.REACT_APP_NODE_ENV === 'production';
export const isStaging = !isLocalhost && !isProduction && process.env.REACT_APP_NODE_ENV === 'staging';

const API_HOST = process.env.REACT_APP_API_HOST?.endsWith('/')
  ? process.env.REACT_APP_API_HOST?.slice(0, -1)
  : process.env.REACT_APP_API_HOST;

export const KRATOS_API = `${API_HOST}/auth`;
export const USERS_API = `${API_HOST}/api/v0/users-svc`;
export const TERMS_AND_PRIVACY_POLICIES = `${API_HOST}/api/v0/tapp`;

export const SOCIAL_LOGIN_PROVIDERS: Array<SocialLoginProviders> = JSON.parse(
  process.env.REACT_APP_SOCIAL_LOGIN_PROVIDERS || '[]',
);

export const checkPassword = (password: string, logger?: ConsoleLogger): boolean => {
  const pwdRegExp = '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])[a-zA-Z0-9!£$%&()=?+_\\-<>\\.]{8,}$';

  if (logger) {
    logger.debug(`check if "${password}" matches the regexp "${pwdRegExp}"`);
  }

  return !!password && new RegExp(pwdRegExp).test(password);
};

export const checkEmail = (email?: string, required = true): boolean =>
  (!required && !email) || (!!email && !!new RegExp(/^[\w-\\.]+@([\w-]+\.)+[\w-]{2,4}$/g).test(email));

export const getTermsAndPrivacyPolicyUrl = (policyCode: string | undefined, userRole: string | undefined): string => {
  let policyType = 'privacy-policy';

  if (policyCode === 'terms_policy') {
    policyType = 'terms-policy';
  }

  const types = [
    {
      regex: '^admin$',
      type: 'admins',
    },
    {
      regex: '^board-manufacturer$',
      type: 'board-manufacturers',
    },
    {
      regex: '^developer-([0-9]+)$',
      type: 'developers',
    },
    {
      regex: '^producer-([0-9]+)$',
      type: 'producers',
    },
    {
      regex: '^producer-([0-9]+)-admin$',
      type: 'producer-admins',
    },
    {
      regex: '^stove-manufacturer--([0-9]+)$',
      type: 'stove-manufacturers',
    },
    {
      regex: '^technician$',
      type: 'technicians',
    },
    {
      regex: '^technician-admin$',
      type: 'technician-admins',
    },
    {
      regex: '^user$',
      type: 'users',
    },
  ];

  let userType = 'users';

  if (userRole) {
    const ut = types.find((t) => new RegExp(t.regex).test(userRole || ''));

    if (ut?.type) {
      userType = ut.type;
    }
  }

  let lang = navigator.language;

  if (!lang || lang.toLowerCase() === 'it') {
    lang = 'it-IT';
  } else if (lang.toLowerCase() === 'en') {
    lang = 'en-GB';
  } else if (lang.toLowerCase() === 'fr') {
    lang = 'fr-FR';
  } else if (lang.toLowerCase() === 'de') {
    lang = 'de-DE';
  } else if (lang.toLowerCase() === 'es') {
    lang = 'es-ES';
  } else if (lang.toLowerCase() === 'ru') {
    lang = 'ru-RU';
  }

  return `${TERMS_AND_PRIVACY_POLICIES}/${userType}/${policyType}?lang=${lang}`;
};

export interface ResponseMessage {
  id: number;
  text: string;
  type: 'info' | 'error' | 'success';
  reason?: string;
}

export const toastResponseMessages = (ui?: UiContainer) => {
  const messages: Array<ResponseMessage> = ((ui?.messages || []) as Array<UiText>).map((m) => ({
    id: m.id,
    text: m.text,
    type: m.type,
    reason: m.context?.['reason'],
  }));

  if (messages.length > 0) {
    for (const msg of messages) {
      switch (msg.type) {
        case 'info':
          toast.info(msg.text);
          break;
        case 'error':
          toast.error(msg.text);
          break;
        case 'success':
          toast.success(msg.text);
          break;
      }
    }
  }
};

export interface UserSettingsApiResp {
  action: string;
  nodes: Array<{
    type: 'input';
    group: string;
    attributes: {
      name: string;
      type: 'hidden' | 'email' | 'text' | 'password';
      value?: string;
      required: boolean;
      disabled: boolean;
      node_type: 'input';
    };
    messages: [];
    meta: {};
  }>;
}

export interface PolicyField {
  title: string;
  name: string;
  value?: string | boolean;
  required: boolean;
  disabled: boolean;
  code: string;
  policyData?: {
    dateName: string;
    dateValue?: string;
    kindName: string;
    kindValue?: string;
  };
}

export interface HiddenField {
  name: string;
  value: string;
}

import {
  Box,
  Button,
  Checkbox,
  Container,
  createTheme,
  CssBaseline,
  FormControlLabel,
  FormGroup,
  Input,
  Tab,
  Tabs,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { deepmerge } from '@mui/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { FormEvent, SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BASENAME, UserSettingsApiResp } from '../../utils/common.util';
import { ConsoleLogger } from '../../utils/logger.util';
import mainTheme from '../../utils/theme.util';
import { SessionApis } from '../../utils/session/sessions.api';
import { updateProfile } from '../../utils/session/ory-client';
import { useAppDispatch } from '../../utils/hooks.util';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import './Profile.css';

import PROFILE_GROUPS from './profile-groups.json';
import { toast } from 'react-toastify';
import { substituteTermsAndPrivacyPolicy } from '../../utils/components.util';

interface UserSettingsField {
  title: string;
  name: string;
  type: 'hidden' | 'email' | 'text' | 'password' | 'policy-check';
  value?: string | boolean;
  required: boolean;
  disabled: boolean;
  code?: string;
  policyData?: {
    dateName: string;
    dateValue?: string;
    kindName: string;
    kindValue?: string;
  };
}

const logger = new ConsoleLogger({ context: 'user-profile' });

const theme = createTheme(deepmerge(mainTheme, {}));

const ProfilePage = () => {
  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const [userSettingsTabs, setUserSettingsTabs] = useState<
    Array<{
      tabId: string;
      fields: Array<UserSettingsField>;
    }>
  >();

  const res = useQuery<UserSettingsApiResp>(['user-settings'], () => SessionApis.getSettings(), {
    enabled: true,
  });

  if (res.isLoading) {
    return <LoadingPage />;
  }
  if (res.isSuccess && !userSettingsTabs) {
    const sessionSettings = res.data || {};

    logger.debug(`User settings: ${JSON.stringify(sessionSettings)}`);

    const uSettings: Array<{
      tabId: string;
      fields: Array<UserSettingsField>;
    }> = [
      ...PROFILE_GROUPS.traitGroups
        .filter(
          (traitGroup) =>
            traitGroup.traits.filter(
              (trait) => !!sessionSettings.nodes?.find(({ attributes }) => attributes.name === trait.code),
            ).length > 0,
        )
        .map((traitGroup) => ({
          tabId: traitGroup.title,
          fields: traitGroup.traits.reduce((traitFields, trait) => {
            const nodeAttributes = sessionSettings.nodes?.find(
              ({ attributes }) => attributes.name === trait.code,
            )?.attributes;

            if (nodeAttributes) {
              traitFields.push({
                title: trait.title,
                name: nodeAttributes.name,
                type: nodeAttributes.type,
                value: nodeAttributes.value,
                required: nodeAttributes.required,
                disabled: nodeAttributes.disabled,
              });
            }

            return traitFields;
          }, [] as Array<UserSettingsField>),
        })),
      {
        tabId: 'policies',
        fields: PROFILE_GROUPS.policies.reduce((policyFields, policy) => {
          const naAccepted = sessionSettings.nodes.find(
            ({ attributes }) => attributes.name === `traits.${policy.code}.accepted`,
          )?.attributes;
          const naDate = sessionSettings.nodes.find(
            ({ attributes }) => attributes.name === `traits.${policy.code}.date`,
          )?.attributes;
          const naKind = sessionSettings.nodes.find(
            ({ attributes }) => attributes.name === `traits.${policy.code}.kind`,
          )?.attributes;

          if (naAccepted && naDate && naKind) {
            policyFields.push({
              title: policy.title,
              name: naAccepted.name,
              type: 'policy-check',
              value: naAccepted.value && naAccepted.value.toString().toLowerCase() === 'true',
              required: policy.mandatory,
              disabled: false,
              code: policy.code,
              policyData: {
                dateName: naDate.name,
                dateValue: naDate.value,
                kindName: naKind.name,
                kindValue: naKind.value,
              },
            });
          }

          return policyFields;
        }, [] as Array<UserSettingsField>),
      },
    ];

    setUserSettingsTabs(uSettings);
  }

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <a href={`${BASENAME}`}>
            <img src={`${BASENAME}/apifire-logo.png`} alt={translate('main.title')} className="logo" />
          </a>
          <UserSettings userSettingsTabs={userSettingsTabs || []} />
        </Box>
      </Container>
    </ThemeProvider>
  );
};

const UserSettings = (props: {
  userSettingsTabs: Array<{
    tabId: string;
    fields: Array<UserSettingsField>;
  }>;
}) => {
  useQueryClient();

  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const dispatch = useAppDispatch();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const fieldMap: { [name: string]: string | boolean | number } = {};

    data.forEach((value, key) => {
      fieldMap[key] = value === 'on' ? true : (value as string);

      logger.debug(`${key} => ${value as string}`);
    });

    const response = dispatch(updateProfile(fieldMap));

    response
      .then((response) => {
        if ((response as any)?.error) {
          logger.debug('User profile update failed', response);
          if (response?.meta?.requestStatus === 'rejected') {
            toast.error(translate('pages.user-profile.errors.rejected'));
          } else {
            toast.error(translate('pages.user-profile.errors.not-updated'));
          }
        } else {
          logger.debug('User profile update successful', response);
          toast.success(translate('pages.user-profile.success.updated'));
        }
      })
      .catch((e) => {
        logger.debug('User profile update failed', e);
        toast.error(translate('pages.user-profile.errors.not-updated'));
      });
  };

  return (
    <Box component="form" onSubmit={handleSubmit}>
      <SettingsTabs tabs={props.userSettingsTabs} />
      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        {translate('pages.user-profile.save')}
      </Button>
    </Box>
  );
};

const SettingsTabs = (props: {
  tabs?: Array<{
    tabId: string;
    fields: Array<UserSettingsField>;
  }>;
}) => {
  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const tabKeys = (props.tabs || []).map((t) => t.tabId);

  const [tabValue, setTabValue] = useState(tabKeys.length > 0 ? tabKeys[0] : '');

  const handleChangeTab = (event: SyntheticEvent, newTabValue: string) => {
    setTabValue(newTabValue);
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mt: 1 }}>
        <Tabs
          value={tabValue}
          onChange={handleChangeTab}
          variant="scrollable"
          scrollButtons="auto"
          aria-label={translate('pages.user-profile.tabs.title')}
        >
          {tabKeys.map((tabKey: string) => (
            <Tab
              label={translate(`pages.user-profile.tabs.${tabKey}`)}
              id={`tab-${tabKey}`}
              key={`tab-${tabKey}`}
              value={tabKey}
              aria-label={translate(`pages.user-profile.tabs.${tabKey}`)}
            />
          ))}
        </Tabs>
      </Box>
      {tabKeys.map((tabKey: string) => (
        <div role="tabpanel" hidden={tabKey !== tabValue} id={`tabpanel-${tabKey}`} key={`tabpanel-${tabKey}`}>
          {props.tabs
            ?.find((t) => t.tabId === tabKey)
            ?.fields.map((field) => (
              <SettingsField key={field.name} {...field} />
            ))}
        </div>
      ))}
    </Box>
  );
};

const SettingsField = (props: UserSettingsField) => {
  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;
  const [value, setValue] = React.useState(props.value);

  logger.debug('Settings Field:', props);

  if (['hidden', 'email', 'text', 'password'].includes(props.type)) {
    const handleTextfieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setValue(event.target.value);
    };

    return (
      <TextField
        margin="normal"
        fullWidth
        id={props.name}
        label={translate(`pages.user-profile.tabs.fields.${props.title}`)}
        type={props.type}
        name={props.name}
        autoComplete={props.name}
        value={value}
        required={!!props.required}
        disabled={!!props.disabled}
        onChange={handleTextfieldChange}
      />
    );
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.policyData?.dateName) {
      props.policyData.dateValue = event.target.checked ? new Date().toISOString() : undefined;
    }

    if (event.target.id === 'privacy_policy' || event.target.id === 'terms_policy') {
      if (event.target.checked) {
        setValue(event.target.checked);
      }
    } else {
      setValue(event.target.checked);
    }
  };

  if (props.type === 'policy-check') {
    const readOnly = (props.code === 'privacy_policy' || props.code === 'terms_policy') && !!value;

    return (
      <>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                required={!!props.required}
                disabled={!!props.disabled}
                readOnly={readOnly}
                name={props.name}
                checked={value as boolean}
                onChange={handleChange}
                id={props.code}
              />
            }
            label={substituteTermsAndPrivacyPolicy(
              props.code || '',
              translate(`pages.user-profile.tabs.fields.${props.title}`),
              translate,
            )}
          />
        </FormGroup>
        {!!props.policyData?.dateName && (
          <Input type="hidden" name={props.policyData.dateName} value={props.policyData.dateValue} />
        )}
        {!!props.policyData?.kindName && (
          <Input type="hidden" name={props.policyData.kindName} value={props.policyData.kindValue} />
        )}
      </>
    );
  }

  logger.error(`type ${props.type} of field ${props.name} isn't supported`);

  return <></>;
};

export default ProfilePage;

import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

export interface ProtectedRouteProps {
  condition: boolean;
  redirect: string;
  children?: React.ReactElement;
}

export const ProtectedRoute = ({ condition, redirect, children }: ProtectedRouteProps) => {
  if (!condition) {
    return <Navigate to={redirect} replace />;
  }

  return children ? children : <Outlet />;
};

import { isProduction } from './common.util';

const DEFAULT_LOG_LEVEL = isProduction ? 'warn' : 'debug';

export interface LogFn {
  // eslint-disable-next-line no-unused-vars
  (message?: any, ...optionalParams: any[]): void;
}

export interface Logger {
  debug: LogFn;
  info: LogFn;
  warn: LogFn;
  error: LogFn;
}

export type LogLevel = 'debug' | 'info' | 'warn' | 'error';

// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
const NO_OP: LogFn = (message?: any, ...optionalParams: any[]) => {};

export class ConsoleLogger implements Logger {
  readonly debug: LogFn;
  readonly info: LogFn;
  readonly warn: LogFn;
  readonly error: LogFn;

  constructor(options?: { context?: string; level?: LogLevel }) {
    const { context, level } = { level: DEFAULT_LOG_LEVEL, ...options };

    const logPrefix = context ? `[${context}]` : '';

    this.error = console.error.bind(console, logPrefix);

    if (level === 'error') {
      this.warn = NO_OP;
      this.info = NO_OP;
      this.debug = NO_OP;

      return;
    }

    this.warn = console.warn.bind(console, logPrefix);

    if (level === 'warn') {
      this.info = NO_OP;
      this.debug = NO_OP;

      return;
    }

    this.info = console.log.bind(console, logPrefix);

    if (level === 'info') {
      this.info = NO_OP;

      return;
    }

    this.debug = console.log.bind(console, logPrefix);
  }
}

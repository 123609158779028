import { Session } from '@ory/kratos-client';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useAppDispatch } from '../hooks.util';
import { ConsoleLogger } from '../logger.util';
import { setUser } from '../user/user.slice';
import { changePassword, fetchSession, login, updateProfile } from './ory-client';
import { SessionState } from './session.types';

const logger = new ConsoleLogger({ context: 'session slice' });

const sessJson = window.localStorage.getItem('session_json');
const logoutURL = window.localStorage.getItem('logout_url');

const initialState: SessionState = {
  session: sessJson ? (JSON.parse(sessJson) as Session) : null,
  loading: 'idle',
  logoutURL,
};

export const sessionSlice = createSlice({
  name: 'session',
  initialState,
  reducers: {
    setSession: (state, action: PayloadAction<{ session: Session | null; logoutUrl: string }>) => {
      logger.debug('reducers set session');

      state.session = action.payload.session;
      state.logoutURL = action.payload.logoutUrl;

      if (!state.session) {
        window.localStorage.removeItem('session_json');
        window.localStorage.removeItem('logout_url');
      }

      return state;
    },
    logout: (state) => {
      logger.debug('reducers logout');

      let logoutUrl = state.logoutURL;

      window.localStorage.removeItem('session_json');
      window.localStorage.removeItem('logout_url');
      state.session = null;
      state.logoutURL = null;

      if (!logoutUrl) {
        logger.error('logout url not found');
      } else {
        logger.debug(`logout url = ${logoutUrl}`);

        window.location.href = logoutUrl;
      }

      return state;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSession.pending, (state) => {
      logger.debug('fetch session: pending');

      state.loading = 'pending';
    });
    builder.addCase(fetchSession.fulfilled, (state, action) => {
      logger.debug('fetch session: fulfilled');

      state.loading = 'succeeded';
      state.session = action.payload.session;
      state.logoutURL = action.payload.logoutUrl;
      state.error = undefined;
    });
    builder.addCase(fetchSession.rejected, (state, action) => {
      logger.debug('fetch session: rejected');

      state.loading = 'failed';
      state.session = null;
      state.error = action.error.message;
      useAppDispatch()(setUser(null));
    });
    builder.addCase(login.pending, (state) => {
      logger.debug('login: pending');

      state.loading = 'pending';
    });
    builder.addCase(login.fulfilled, (state, action) => {
      logger.debug('login: fulfilled');

      state.loading = 'succeeded';
      state.session = action.payload.session?.session || null;
      state.logoutURL = action.payload.logoutUrl;
      state.error = undefined;
    });
    builder.addCase(login.rejected, (state, action) => {
      logger.debug('login: rejected');

      state.loading = 'failed';
      state.session = null;
      state.error = action.error.message;
    });
    builder.addCase(changePassword.pending, (state) => {
      logger.debug('change password: pending');

      state.loading = 'pending';
    });
    builder.addCase(changePassword.fulfilled, (state) => {
      logger.debug('change password: fulfilled');

      state.loading = 'succeeded';
      state.error = undefined;
    });
    builder.addCase(changePassword.rejected, (state, action) => {
      logger.debug('change password: rejected');
      logger.debug(`action = ${JSON.stringify(action)}`);

      state.loading = 'failed';

      const msgErrors = (action.payload as { errors?: Array<string> })?.errors || [];

      state.error = msgErrors.length === 0 ? 'errors.unknown' : msgErrors;
    });
    builder.addCase(updateProfile.pending, (state) => {
      logger.debug('update profile: pending');

      state.loading = 'pending';
    });
    builder.addCase(updateProfile.fulfilled, (state) => {
      logger.debug('update profile: fulfilled');

      state.loading = 'succeeded';
      state.error = undefined;
    });
    builder.addCase(updateProfile.rejected, (state, action) => {
      logger.debug('update profile: rejected');

      state.loading = 'failed';
      state.error = action.error.message;
    });
  },
});

export const { setSession, logout } = sessionSlice.actions;
export default sessionSlice.reducer;

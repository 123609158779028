import {
  Box,
  Button,
  Container,
  createTheme,
  CssBaseline,
  IconButton,
  Input,
  InputAdornment,
  TextField,
  ThemeProvider,
} from '@mui/material';
import { deepmerge } from '@mui/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { FormEvent, MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BASENAME, checkPassword, HiddenField, PolicyField, UserSettingsApiResp } from '../../utils/common.util';
import { ConsoleLogger } from '../../utils/logger.util';
import mainTheme from '../../utils/theme.util';
import { SessionApis } from '../../utils/session/sessions.api';
import { updateProfile } from '../../utils/session/ory-client';
import { useAppDispatch } from '../../utils/hooks.util';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import { changePassword } from '../../utils/session/ory-client';
import PROFILE_GROUPS from '../profile/profile-groups.json';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { PolicyCheckField } from '../../utils/components.util';
import './FirstSettings.css';

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const logger = new ConsoleLogger({ context: 'first-settings' });

const theme = createTheme(deepmerge(mainTheme, {}));

const FirstSettingsPage = () => {
  logger.debug('first settings page');

  useQueryClient();

  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [disableAll, setDisableAll] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [errors, setErrors] = useState<{ new?: string; repeat?: string }>({});
  const [policyFields, setPolicyFields] = useState<Array<PolicyField>>();
  const [hiddenFields, setHiddenFields] = useState<Array<HiddenField>>();

  const res = useQuery<UserSettingsApiResp>(['user-settings'], () => SessionApis.getSettings(), {
    enabled: true,
  });

  if (res.isLoading) {
    return <LoadingPage />;
  }
  if (res.isSuccess && (!policyFields || !hiddenFields)) {
    const sessionSettings = res.data || {};

    logger.debug(`User settings: ${JSON.stringify(sessionSettings)}`);

    setPolicyFields(
      PROFILE_GROUPS.policies.reduce((policyFields, policy) => {
        const naAccepted = sessionSettings.nodes.find(
          ({ attributes }) => attributes.name === `traits.${policy.code}.accepted`,
        )?.attributes;
        const naDate = sessionSettings.nodes.find(
          ({ attributes }) => attributes.name === `traits.${policy.code}.date`,
        )?.attributes;
        const naKind = sessionSettings.nodes.find(
          ({ attributes }) => attributes.name === `traits.${policy.code}.kind`,
        )?.attributes;

        if (naAccepted && naDate && naKind) {
          policyFields.push({
            title: policy.title,
            name: naAccepted.name,
            value: naAccepted.value && naAccepted.value.toString().toLowerCase() === 'true',
            required: policy.mandatory,
            disabled: false,
            code: policy.code,
            policyData: {
              dateName: naDate.name,
              dateValue: naDate.value,
              kindName: naKind.name,
              kindValue: naKind.value,
            },
          });
        }

        return policyFields;
      }, [] as Array<PolicyField>),
    );

    setHiddenFields(
      sessionSettings.nodes?.reduce((hiddenFields, currNode) => {
        if (
          currNode.type === 'input' &&
          currNode.group === 'profile' &&
          !!currNode.attributes?.value &&
          !policyFields?.find((pf) => pf.name === currNode.attributes?.name)
        ) {
          hiddenFields.push({
            name: currNode.attributes?.name,
            value: currNode.attributes?.value,
          });
        }

        return hiddenFields;
      }, [] as Array<HiddenField>),
    );
  }

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setDisableAll(true);

    const data = new FormData(event.currentTarget);

    const fieldMap: { [name: string]: string | boolean | number } = {};

    data.forEach((value, key) => {
      fieldMap[key] = value === 'on' ? true : (value as string);

      logger.debug(`${key} => ${value as string}`);
    });

    const newPassword = data.get('field-new-password') as string;
    const repeatPassword = data.get('field-repeat-password') as string;

    const errors: { new?: string; repeat?: string } = {};

    if (!checkPassword(newPassword, logger)) {
      errors.new = translate('pages.first-settings.errors.password');
    }

    if (!showPassword && repeatPassword !== newPassword) {
      errors.repeat = translate('pages.first-settings.errors.repeat');
    }

    setErrors(errors);

    if (!!errors.new || !!errors.repeat) {
      setDisableAll(false);

      return;
    }

    const updateRes = await dispatch(updateProfile(fieldMap));

    const changeRes = await dispatch(changePassword(newPassword));

    if (updateRes.meta.requestStatus === 'fulfilled' && changeRes.meta.requestStatus === 'fulfilled') {
      setDisableAll(false);
      SessionApis.getVerificationFlow().then((sessionToken) => {
        navigate({
          pathname: '/verification',
          search: createSearchParams({
            flow: sessionToken,
          }).toString(),
        });
      });
    }

    return;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={`${BASENAME}/apifire-logo.png`} alt={translate('main.title')} className="logo" />
          <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{ width: '100%' }}>
              {hiddenFields?.map((hiddenField) => (
                <Input type="hidden" key={hiddenField.name} name={hiddenField.name} value={hiddenField.value} />
              ))}
              {policyFields?.map((policyField) => (
                <PolicyCheckField
                  key={policyField.name}
                  title={policyField.title}
                  name={policyField.name}
                  value={policyField.value}
                  required={policyField.required}
                  disabled={policyField.disabled}
                  policyData={policyField.policyData}
                  disableAll={disableAll}
                  code={policyField.code}
                />
              ))}
              <>
                <TextField
                  margin="normal"
                  fullWidth
                  name={'field-new-password'}
                  label={translate('pages.first-settings.fields.password')}
                  type={showPassword ? 'text' : 'password'}
                  required={true}
                  disabled={disableAll}
                  error={!!errors.new}
                  helperText={errors.new}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label={translate('pages.login.toggle-password-visibility')}
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name={'field-repeat-password'}
                  label={translate('pages.first-settings.fields.check-password')}
                  type="password"
                  required={!showPassword}
                  disabled={disableAll || showPassword}
                  error={!!errors.repeat}
                  helperText={errors.repeat}
                />
              </>
            </Box>
            <Button type="submit" fullWidth variant="contained" disabled={disableAll} sx={{ mt: 3, mb: 2 }}>
              {translate('pages.first-settings.save')}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default FirstSettingsPage;

import { Box, Button, Container, createTheme, CssBaseline, Input, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BASENAME } from '../../utils/common.util';
import { ConsoleLogger } from '../../utils/logger.util';
import mainTheme from '../../utils/theme.util';
import { SessionApis } from '../../utils/session/sessions.api';
import { updateProfile } from '../../utils/session/ory-client';
import { useAppDispatch } from '../../utils/hooks.util';
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import PROFILE_GROUPS from '../profile/profile-groups.json';
import { useNavigate } from 'react-router-dom';
import { PolicyCheckField } from '../../utils/components.util';
import './MandatoryPolicies.css';

interface UserSettingsApiResp {
  action: string;
  nodes: Array<{
    type: 'input';
    group: string;
    attributes: {
      name: string;
      type: 'hidden' | 'email' | 'text' | 'password';
      value?: string;
      required: boolean;
      disabled: boolean;
      node_type: 'input';
    };
    messages: [];
    meta: {};
  }>;
}

interface PolicyField {
  title: string;
  name: string;
  value?: string | boolean;
  required: boolean;
  disabled: boolean;
  code: string;
  policyData?: {
    dateName: string;
    dateValue?: string;
    kindName: string;
    kindValue?: string;
  };
}

interface HiddenField {
  name: string;
  value: string;
}

const logger = new ConsoleLogger({ context: 'first-settings' });

const theme = createTheme(deepmerge(mainTheme, {}));

const MandatoryPoliciesPage = () => {
  logger.debug('first settings page');

  useQueryClient();

  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [disableAll, setDisableAll] = useState<boolean>(false);
  const [policyFields, setPolicyFields] = useState<Array<PolicyField>>();
  const [hiddenFields, setHiddenFields] = useState<Array<HiddenField>>();

  const res = useQuery<UserSettingsApiResp>(['user-settings'], () => SessionApis.getSettings(), {
    enabled: true,
  });

  if (res.isLoading) {
    return <LoadingPage />;
  }
  if (res.isSuccess && (!policyFields || !hiddenFields)) {
    const sessionSettings = res.data || {};

    logger.debug(`User settings: ${JSON.stringify(sessionSettings)}`);

    setPolicyFields(
      PROFILE_GROUPS.policies.reduce((policyFields, policy) => {
        const naAccepted = sessionSettings.nodes.find(
          ({ attributes }) => attributes.name === `traits.${policy.code}.accepted`,
        )?.attributes;
        const naDate = sessionSettings.nodes.find(
          ({ attributes }) => attributes.name === `traits.${policy.code}.date`,
        )?.attributes;
        const naKind = sessionSettings.nodes.find(
          ({ attributes }) => attributes.name === `traits.${policy.code}.kind`,
        )?.attributes;

        if (naAccepted && naDate && naKind) {
          policyFields.push({
            title: policy.title,
            name: naAccepted.name,
            value: naAccepted.value && naAccepted.value.toString().toLowerCase() === 'true',
            required: policy.mandatory,
            disabled: false,
            code: policy.code,
            policyData: {
              dateName: naDate.name,
              dateValue: naDate.value,
              kindName: naKind.name,
              kindValue: naKind.value,
            },
          });
        }

        return policyFields;
      }, [] as Array<PolicyField>),
    );

    setHiddenFields(
      sessionSettings.nodes?.reduce((hiddenFields, currNode) => {
        if (
          currNode.type === 'input' &&
          currNode.group === 'profile' &&
          !!currNode.attributes?.value &&
          !policyFields?.find((pf) => pf.name === currNode.attributes?.name)
        ) {
          hiddenFields.push({
            name: currNode.attributes?.name,
            value: currNode.attributes?.value,
          });
        }

        return hiddenFields;
      }, [] as Array<HiddenField>),
    );
  }

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    setDisableAll(true);

    const data = new FormData(event.currentTarget);

    const fieldMap: { [name: string]: string | boolean | number } = {};

    data.forEach((value, key) => {
      fieldMap[key] = value === 'on' ? true : (value as string);

      logger.debug(`${key} => ${value as string}`);
    });

    const updateRes = await dispatch(updateProfile(fieldMap));

    if (updateRes.meta.requestStatus === 'fulfilled') {
      setDisableAll(false);
      navigate({ pathname: '/' });
    }

    return;
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={`${BASENAME}/apifire-logo.png`} alt={translate('main.title')} className="logo" />
          <Box component="form" onSubmit={handleSubmit}>
            <Box sx={{ width: '100%' }}>
              {hiddenFields?.map((hiddenField) => (
                <Input type="hidden" key={hiddenField.name} name={hiddenField.name} value={hiddenField.value} />
              ))}
              {policyFields?.map((policyField) => (
                <PolicyCheckField
                  key={policyField.name}
                  title={policyField.title}
                  name={policyField.name}
                  value={policyField.value}
                  required={policyField.required}
                  disabled={policyField.disabled}
                  policyData={policyField.policyData}
                  disableAll={disableAll}
                  code={policyField.code}
                />
              ))}
            </Box>
            <Button type="submit" fullWidth variant="contained" disabled={disableAll} sx={{ mt: 3, mb: 2 }}>
              {translate('pages.first-settings.save')}
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default MandatoryPoliciesPage;

import React, { FormEvent, MouseEvent, useCallback, useState } from 'react';
import { deepmerge } from '@mui/utils';
import {
  Box,
  Container,
  createTheme,
  CssBaseline,
  Grid,
  TextField,
  ThemeProvider,
  Link,
  InputAdornment,
  IconButton,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useTranslation } from 'react-i18next';
import { BASENAME } from '../../utils/common.util';
import mainTheme from '../../utils/theme.util';
import { Navigate, useLocation } from 'react-router-dom';
import { LoginResponse, SessionApis } from '../../utils/session/sessions.api';
import { toast } from 'react-toastify';
import { ConsoleLogger } from '../../utils/logger.util';
import { SocialLoginProviders, doSocialProviderLogin } from '../../utils/social-providers.util';
import { SocialLoginButtons } from '../../utils/components.util';
import './Login.css';

import EmailIcon from '@mui/icons-material/Email';
import PasswordIcon from '@mui/icons-material/Password';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const logger = new ConsoleLogger({ context: 'login' });

const theme = createTheme(deepmerge(mainTheme, {}));

export interface LoginPageProps {
  alreadyLogged?: boolean;
  onLogin: (data: LoginResponse) => void;
}

const LoginPage = (props: LoginPageProps) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [disableAll, setDisableAll] = useState<boolean>(false);
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);

  const location = useLocation();

  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  let from = location.state?.from?.pathname || BASENAME;

  logger.debug(`from location ${from}`);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();
    let loginRet: LoginResponse = {};
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;

    setIsLoggingIn(true);
    setDisableAll(true);
    loginRet = await SessionApis.login(email, password);
    if (loginRet.hasErrors && loginRet.errors?.length) {
      setIsLoggingIn(false);
      setDisableAll(false);

      loginRet.errors.forEach((error) => {
        toast.error(error.message);
      });
    } else {
      props.onLogin(loginRet);
    }
  };

  const handleSocialLoginBtnClick = useCallback(async (provider: SocialLoginProviders) => {
    logger.debug(`clicked the social login button of the ${provider} provider`);

    if (disableAll) {
      logger.warn(`do not proceed with social login of the ${provider} provider, beacuse it's all disabled`);

      return;
    }

    setIsLoggingIn(true);
    setDisableAll(true);

    try {
      await doSocialProviderLogin(provider);
    } catch (err) {
      setDisableAll(false);
      setDisableAll(false);

      toast.error(translate('pages.login.errors.not-logged-in'));

      toast.error(err.message || translate('errors.unknown'));
    }
  }, []);

  if (props?.alreadyLogged) {
    logger.debug('user already logged');

    return <Navigate to="/" replace />;
  } else {
    logger.debug('user not logged yet');

    return (
      <ThemeProvider theme={theme}>
        <Container component="main" maxWidth="xs">
          <CssBaseline />
          <Box
            sx={{
              marginTop: 20,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <img src={`${BASENAME}/apifire-logo.png`} alt={translate('main.title')} className="logo" />
            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={translate('pages.login.email-address')}
                type="email"
                name="email"
                autoComplete="email"
                autoFocus
                disabled={disableAll}
                InputProps={{ startAdornment: <EmailIcon style={{ marginRight: '5px' }} /> }}
              />
              <TextField
                margin="normal"
                required
                fullWidth
                id="password"
                name="password"
                label={translate('pages.login.password')}
                type={showPassword ? 'text' : 'password'}
                autoComplete="password"
                disabled={disableAll}
                InputProps={{
                  startAdornment: <PasswordIcon style={{ marginRight: '5px' }} />,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={translate('pages.login.toggle-password-visibility')}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <LoadingButton fullWidth type="submit" loading={isLoggingIn} variant="contained" sx={{ mt: 3, mb: 2 }}>
                {translate('pages.login.signIn')}
              </LoadingButton>
              {!disableAll && (
                <Grid container>
                  <Grid item>
                    <Link href={`${BASENAME}/recover-password`} variant="body2">
                      {translate('pages.login.recover-password')}?
                    </Link>
                  </Grid>
                </Grid>
              )}
              <SocialLoginButtons
                before={
                  <>
                    <br />
                    <br />
                  </>
                }
                isUserLogged={false}
                handleProvider={handleSocialLoginBtnClick}
              />
            </Box>
          </Box>
        </Container>
      </ThemeProvider>
    );
  }
};

export default LoginPage;

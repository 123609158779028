import React from 'react';
import { useAppDispatch } from '../../utils/hooks.util';
import { logout } from '../../utils/session/session.slice';
import './Logout.css';

function LogoutPage() {
  const dispatch = useAppDispatch();

  dispatch(logout());

  return <></>;
}

export default LogoutPage;

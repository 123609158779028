import { Box, Button, Container, createTheme, CssBaseline, TextField, ThemeProvider } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { useQueryClient } from '@tanstack/react-query';
import React, { FormEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BASENAME, checkEmail } from '../../utils/common.util';
import { ConsoleLogger } from '../../utils/logger.util';
import { SessionApis } from '../../utils/session/sessions.api';
import { toast } from 'react-toastify';
import mainTheme from '../../utils/theme.util';
import './Verification.css';

const logger = new ConsoleLogger({ context: 'verification' });

const theme = createTheme(deepmerge(mainTheme, {}));

const VerificationPage = () => {
  useQueryClient();

  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const [flow, setFlow] = useState<string>();
  const [code, setCode] = useState<string>();
  const [email, setEmail] = useState<string>();

  const queryParams = new URLSearchParams(window.location.search);

  useEffect(() => {
    setFlow(queryParams.get('flow') as string);

    logger.debug(`verification flow: ${flow}`);

    setCode(queryParams.get('code') as string);

    logger.debug(`verification code: ${code}`);

    setEmail(queryParams.get('email') as string);

    logger.debug(`verification email: ${email}`);
  });

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 20,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <img src={`${BASENAME}/apifire-logo.png`} alt={translate('main.title')} className="logo" />
          {!flow && <ResendVerificationLink email={email} />}
          {flow && <VerifyEmail flow={flow} code={code} email={email} />}
        </Box>
      </Container>
    </ThemeProvider>
  );
};

const ResendVerificationLink = (props: { email?: string }) => {
  useQueryClient();

  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const [emailError, setEmailError] = useState<string>();

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;

    if (!checkEmail(email)) {
      setEmailError(translate('pages.verification.errors.email'));

      return;
    }

    const rvlRet = await SessionApis.resendVerificationLink(email);

    if (rvlRet.ok) {
      logger.info('Resend verification link successful');

      toast.success(translate('pages.verification.resended'));

      setTimeout(() => {
        window.location.href = BASENAME;
      }, 1500);
    } else {
      logger.error('Resend verification link failed');

      toast.error(translate('pages.verification.errors.not-resended'));
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <div className="login-text">{translate('pages.verification.instructions.send-verification')}</div>

      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label={translate('pages.verification.fields.email-address')}
        type="email"
        name="email"
        autoComplete="email"
        autoFocus
        defaultValue={props.email}
        error={!!emailError}
        helperText={emailError}
      />

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        {translate('pages.verification.send-verification-email')}
      </Button>
    </Box>
  );
};

const VerifyEmail = (props: { flow: string; code?: string; email?: string }) => {
  useQueryClient();

  const { t } = useTranslation();
  const translate = (text: string) => t(text) || text;

  const [errors, setErrors] = useState<{ email?: string; code?: string }>({});

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const code = data.get('code') as string;

    let errEmail;
    let errCode;

    if (!checkEmail(email)) {
      errEmail = translate('pages.verification.errors.email');
    }

    if (!code || code.length < 1) {
      errCode = translate('pages.verification.errors.code');
    }

    if (errCode || errEmail) {
      setErrors({ email: errEmail, code: errCode });

      return;
    }

    const veRet = await SessionApis.verifyEmail(props.flow, code, email);

    if (veRet.ok) {
      logger.info('Emil verified successfully');

      toast.success(translate('pages.verification.verified'));

      setTimeout(() => {
        window.location.href = BASENAME;
      }, 1500);
    } else {
      logger.error('Error on verify email');

      toast.error(translate('pages.verification.errors.not-verified'));
    }
  };

  return (
    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
      <div className="login-text">{translate('pages.verification.instructions.verify')}</div>

      <TextField
        margin="normal"
        required
        fullWidth
        id="email"
        label={translate('pages.verification.fields.email-address')}
        type="email"
        name="email"
        autoComplete="email"
        autoFocus
        defaultValue={props.email}
        error={!!errors.email}
        helperText={errors.email}
      />
      <TextField
        margin="normal"
        required
        fullWidth
        id="code"
        label={translate('pages.verification.fields.code')}
        type="text"
        name="code"
        autoComplete="code"
        defaultValue={props.code}
        error={!!errors.code}
        helperText={errors.code}
      />

      <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
        {translate('pages.verification.verify')}
      </Button>
    </Box>
  );
};

export default VerificationPage;

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import DispatchPage from './pages/dispatch/Dispatch';
import LoginPage from './pages/login/Login';
import ProfilePage from './pages/profile/Profile';
import { ToastContainer } from 'react-toastify';
import { LoginResponse, SessionApis } from './utils/session/sessions.api';
import { QueryClient, QueryClientProvider, useQuery, useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { isProduction } from './utils/common.util';
import { setSession } from './utils/session/session.slice';
import { useAppDispatch, useAppSelector } from './utils/hooks.util';
import { ProtectedRoute } from './components/ProtectedRoute/ProtectedRoute';
import LogoutPage from './pages/logout/Logout';
import RecoverPasswordPage from './pages/recover-password/RecoverPassword';
import { ConsoleLogger } from './utils/logger.util';
import ChangePasswordPage from './pages/change-password/ChangePassword';
import ErrorPage from './pages/error/Error';
import VerificationPage from './pages/verification/Verification';
import RecoveryPage from './pages/recovery/Recovery';
import FirstSettingsPage from './pages/first-settings/FirstSettings';
import SettingsDispatch from './pages/settings-dispatch/SettingsDispatch';
import DeleteAccountPage from './pages/delete-account/DeleteAccount';
import LinkSocialLoginPage from './pages/link-social-login/LinkSocialLogin';
import MandatoryPoliciesPage from './pages/mandatory-policies/MandatoryPolicies';

const logger = new ConsoleLogger({ context: 'app' });

const queryClient = new QueryClient();

export const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={!isProduction} />
      <RoutesLayout />
      <ToastContainer progressClassName="toastProgress" bodyClassName="toastBody" position="bottom-right" />
    </QueryClientProvider>
  );
};

const RoutesLayout = () => {
  useQueryClient();

  const stateSession = useAppSelector((state) => state.session);
  const dispatch = useAppDispatch();

  useQuery(['session'], SessionApis.fetchSession, {
    onSuccess: (data) => {
      logger.debug(`fetch session success: ${JSON.stringify(data)}`);

      dispatch(setSession(data || { session: null, logoutUrl: '' }));
    },
    onError: (error) => {
      console.warn('error on fetch session', error);
      dispatch(setSession({ session: null, logoutUrl: '' }));
    },
    enabled: !stateSession || !stateSession.session,
  });

  logger.debug(`state session = ${JSON.stringify(stateSession)}`);

  const handleLogin = (data: LoginResponse) => {
    dispatch(setSession({ session: data.session.session, logoutUrl: data.logoutUrl || '' }));
  };

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute redirect="/login" condition={!!stateSession.session}>
            <DispatchPage loggedUserId={stateSession.session?.identity?.id} />
          </ProtectedRoute>
        }
      />
      <Route path="/login" element={<LoginPage alreadyLogged={!!stateSession.session} onLogin={handleLogin} />} />
      <Route path="/recover-password" element={<RecoverPasswordPage />} />
      <Route
        path="/logout"
        element={
          <ProtectedRoute redirect="/login" condition={!!stateSession.session}>
            <LogoutPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/user-profile"
        element={
          <ProtectedRoute redirect="/login" condition={!!stateSession.session}>
            <ProfilePage />
          </ProtectedRoute>
        }
      />
      <Route path="/change-password" element={<ChangePasswordPage stateSession={stateSession} />} />
      <Route
        path="/delete-account"
        element={
          <ProtectedRoute redirect="/login" condition={!!stateSession.session}>
            <DeleteAccountPage stateSession={stateSession} />
          </ProtectedRoute>
        }
      />
      <Route
        path="/link-social-login"
        element={
          <ProtectedRoute redirect="/login" condition={!!stateSession.session}>
            <LinkSocialLoginPage />
          </ProtectedRoute>
        }
      />
      <Route path="/verification" element={<VerificationPage />} />
      <Route path="/recovery" element={<RecoveryPage />} />
      <Route path="/error" element={<ErrorPage />} />
      <Route path="/first-settings" element={<FirstSettingsPage />} />
      <Route path="/mandatory-policies" element={<MandatoryPoliciesPage />} />
      <Route path="/settings-dispatch" element={<SettingsDispatch session={stateSession} />} />
    </Routes>
  );
};

export default App;
